export default {
    install(app) {
        // 添加全局方法
        //从文章里面截取照片路径
        app.config.globalProperties.$getUrlForStr = function (str) {
            // 你的方法逻辑
            var imgTagRegex = /<img[^>]+src=['"]([^'"]+)['"][^>]*>/g;
            var match;
            if(((match = imgTagRegex.exec(str)) !== null)){
                var src = match[1];
                return match[1];
            }
            return null;
        };
    }
};
