import request from "../utils/request";


//新闻资讯列表
export function recentNews(data) {
    return request({
        url: `/api/news/recentNews`,
        method: 'get',
        params:data
    })
}

//新闻分页
export function outPageNews(data) {
    return request({
        url: `/api/news/outPageNews`,
        method: 'get',
        params:data
    })
}

//新闻详情
export function outDetailNews(data) {
    return request({
        url: `/api/news/outDetailNews`,
        method: 'get',
        params:data
    })
}

//最近三期的期刊封面
export function recentPeriodicals(data) {
    return request({
        url: `/api/periodical/recentPeriodicals`,
        method: 'get',
    })
}

//查询所有期刊信息
export function allPeriodicals(data) {
    return request({
        url: `/api/periodical/allPeriodicals`,
        method: 'get',
    })
}

//查询当前期刊所有板块、主题
export function topicsByPId(data) {
    return request({
        url: `/api/paper/topicsByPId`,
        method: 'get',
        params:data
    })
}

//根据期刊号查询文章信息
export function papersByPId(data) {
    return request({
        url: `/api/paper/papersByPId`,
        method: 'get',
        params:data
    })
}
