<template>
    <div class="header_box">
        <div class="grid-logo">
            <img src="../assets/images/header/header_logo.png" width="223" height="47" class="header_logo">
        </div>
        <div class="grid-content">
            <router-link to="/homePage" class="router-link">首页</router-link>
        </div>
        <div class="grid-content">
            <el-dropdown>
                <span class="el-dropdown-link">解决方案</span>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item style="padding: 0 0;">
                            <router-link to="/science" class="router-link">
                                <div class="dropdownItem">科技数字化</div>
                            </router-link>
                        </el-dropdown-item>
                        <el-dropdown-item style="padding: 0 0;">
                            <router-link to="/tax" class="router-link">
                                <div class="dropdownItem">税务数字化</div>
                            </router-link>
                        </el-dropdown-item>
                        <el-dropdown-item style="padding: 0 0;">
                            <router-link to="/technology" class="router-link">
                                <div class="dropdownItem">技术服务数字一体化</div>
                            </router-link>
                        </el-dropdown-item>
                        <el-dropdown-item style="padding: 0 0;">
                            <router-link to="/system" class="router-link">
                                <div class="dropdownItem">系统集成一体化</div>
                            </router-link>
                        </el-dropdown-item>
<!--                        <el-dropdown-item style="padding: 0 0;">-->
<!--                            <router-link to="/journal" class="router-link">-->
<!--                                <div class="dropdownItem">省级科技期刊</div>-->
<!--                            </router-link>-->
<!--                        </el-dropdown-item>-->
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
        <!--<div class="grid-content">产品中心</div>
        <div class="grid-content">服务支持</div>-->
        <div class="grid-content">
            <router-link  to="/" class="router-link"  @click="toJoinUs">加入我们</router-link>
            <!--<el-button  class="router-link" @click="toJoinUs">加入我们</el-button>-->
        </div>
      <!--
        <div class="grid-content">
            <router-link to="/science" class="router-link">科技数字化</router-link>
        </div>
        <div class="grid-content">
            <router-link to="/tax" class="router-link">税务数字化</router-link>
        </div>
        <div class="grid-content">
            <router-link to="/system" class="router-link">系统集成一体化</router-link>
        </div>
        <div class="grid-content">
            <router-link to="/journal" class="router-link">省级科技期刊</router-link>
        </div>-->
        <div class="grid-content"> <el-button :type="theme=='default'?'primary':'info'" @click="toggoleTheme">长者版</el-button></div>
    </div>
</template>

<script>
    import {recentNews} from "@/api";

    export default {
        name: "Header",
        data(){
            return{
                theme: 'default'
            }
        },
        methods: {
          toJoinUs() {
            recentNews({topic: '加入我们'}).then(res => {
              // console.log(res)
              if (res.length > 0) {
                let item = res[0];
                let pathInfo = this.$router.resolve({
                  path: '/new',
                  query: {
                    id: item.id,
                  }
                });
                window.open(pathInfo.href, '_blank');
              }
            })
                .catch(err => {
                })
          },
            // 当前分为white主题和black主题进行切换
            toggoleTheme() {
                this.theme = this.theme === 'older' ? 'default' : 'older';
                // 通过setAttribute设置data-theme主题进行切换
                document.documentElement.setAttribute('data-theme', this.theme );
            }
        },
        created() {
            // 通过setAttribute设置data-theme主题进行切换
            document.documentElement.setAttribute('data-theme', this.theme );
        }

    }
</script>

<style lang="scss">
    @import "../assets/css/handle.scss";
    #app {
        @include font_size($font-size-default)
    }
    .el-button{
        @include font_size($font-size-default)
    }
    .el-dropdown-link{
        @include font_size($font-size-default)
    }
    .text-center{
        text-align: center;
    }
</style>
<style scoped lang="scss">
    .header_box{
        padding: 24px 200px;
        display: flex;
        align-items: center;
        .grid-logo{
            flex: 1;
        }
        .grid-content{
            margin-left: 80px;
            font-weight: 500;
            font-size: 22px;
            color: #333333;
            line-height: 32px;
        }
    }
    .el-dropdown-link:focus {
        outline: none !important;
    }
    .el-dropdown{
        line-height: normal;
    }
    .el-dropdown-menu{
        padding: 20px 0;
    }
    .router-link{
        width: 100%;
        text-decoration: none;
        color: #606266;
        padding: 5px 16px;
    }
    .router-link-active{
        color: #409eff;
        background-color: #ecf5ff;
    }



</style>
