import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'     //需要引入的部分1
import 'element-plus/dist/index.css'       //需要引入的部分2
import 'swiper/swiper-bundle.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import utilsPlugin from './utils/index'


// createApp(App).use(store).use(router).use(ElementPlus).mount('#app');


const app = createApp(App);

app.use(store);
app.use(router);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(ElementPlus);
app.use(utilsPlugin);
app.mount("#app");



