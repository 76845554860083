import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    //一级路由
  {
    path: '/home',
    name: 'home',
    component:  () => import('../views/HomeView.vue'),
    redirect:'/homePage',
    children:[
        //二级路由
      {path:'/homePage',name:'homePage',component:() => import( '../views/page/Home.vue')},
      {path:'/science',name:'science',component:() => import( '../views/page/Science.vue')},
      {path:'/tax',name:'tax',component:() => import( '../views/page/Tax.vue')},
      {path:'/system',name:'system',component:() => import( '../views/page/System.vue')},
      {path:'/journal',name:'journal',component:() => import( '../views/page/Journal.vue')},
      {path:'/new',name:'new',component:() => import( '../views/page/New.vue')},
      {path:'/newList',name:'newList',component:() => import( '../views/page/NewList.vue')},
      {path:'/technology',name:'technology',component:() => import( '../views/page/technology.vue')},
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {path:'/',redirect:'/home'}

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router
