<template>
    <!--<nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>-->
    <router-view/>
</template>

<script>
    import Header from "./components/Header";

    export default {
        components: {Header},
        methods: {
            resize() {
                // 系统整体缩放
                let cliWidth =
                    document.documentElement.clientWidth || document.body.clientWidth
                let cliHeight =
                    document.documentElement.clientHeight || document.body.clientHeight
                // let contW = 1947
                // let contH = 891 // 59 / 27 1960 * 27/59
                let contW = 1960
                let contH = 897
                // let contW = this.contW
                // let contH = this.contH
                // let urlPath = window.location.href
                // let pathName = urlPath.split('#')[1]
                // if (
                //         pathName.indexOf('home') != -1 ||
                //         pathName === '/' ||
                //         pathName.indexOf('gbimg') != -1
                // ) {
                //   contW = 1920
                //   contH = 1080
                // } else {
                //   contW = 1960
                //   contH = 897
                // }

                // if (
                //   Math.ceil(cliWidth / cliHeight) === 2 ||
                //   Math.floor(cliWidth / cliHeight) === 2
                // ) {
                //   contW = 1947
                //   contH = 891
                //   console.log(2222)
                // }

                let w = cliWidth / contW
                let h = cliHeight / contH
                let appDom = document.querySelector('#app')
                let size = cliWidth / cliHeight
                if (cliWidth === screen.width) {
                    appDom.style.transform = 'scale(' + w + ',' + h + ')'
                } else if (size > contW / contH) {
                    appDom.style.transform = 'scale(' + h + ',' + h + ')'
                } else {
                    appDom.style.transform = 'scale(' + w + ',' + w + ')'
                }
                appDom.style.transformOrigin = 'top left'
                appDom.style.width = contW + 'px'
                appDom.style.height = contH + 'px'
            },
            handleScroll(e) {
                e
            }
        },
        created() {
            // 判断 token 是否在本地保存
            // if (!localStorage.getItem('token')) {
            //   this.getToken()
            // } else {
            //   this.$store.commit('setToken', localStorage.getItem('token'))
            // }

            //  var serverPath = curWwwPath.substring(0, index);//服务器地址 http://10.15.5.83:5555
            this.resize();
            window.addEventListener('resize', () => {
                this.resize()
            })
            window.onload = () => {
                return this.resize()
            }
        },
    }
</script>

<style lang="scss">
    * {
        box-sizing: border-box;
    }

    body {
        display: block;
        margin: 0 0;
        padding: 0 0;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #333333;
    }

</style>
